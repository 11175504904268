import React from "react";
import { Helmet } from "react-helmet";
import { Link } from "gatsby";
import { navigate } from "gatsby-link";
import Layout from "../../components/Layout";
//import Recaptcha from "react-google-recaptcha";

//const RECAPTCHA_KEY = process.env.SITE_RECAPTCHA_KEY;
const recaptchaRef = React.createRef();

function encode(data) {
  return Object.keys(data)
    .map((key) => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
    .join("&");
}

export default class Index extends React.Component {
  constructor(props) {
    super(props);
    this.state = { isValidated: false };
  }

  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  handleRecaptcha = (value) => {
    this.setState({ "g-recaptcha-response": value });
  };

  handleSubmit = (e) => {
    e.preventDefault();
    const recaptchaValue = recaptchaRef.current.getValue();
    if (recaptchaValue !== "") {
      const form = e.target;
      fetch("/contact?no-cache=1", {
        method: "POST",
        headers: { "Content-Type": "application/x-www-form-urlencoded" },
        body: encode({
          "form-name": form.getAttribute("name"),
          ...this.state,
        }),
      })
        .then(() => navigate(form.getAttribute("action")))
        .catch((error) => alert(error));
    } else {
      alert("Please complete the CAPTCHA");
    }
  };

  render() {
    return (
      <Layout>
        <Helmet>
          <title>Contact Us | Pathlab</title>
          <meta name="description" content="Get in touch with Pathlab." />
        </Helmet>
        <section className="section">
          <div className="container content-border">
            <div className="columns">
              <div className="column is-8 is-offset-2 content">
                <div className="columns is-desktop">
                  <div className="column is-6-desktop">
                    <h1>Urgent medical issue? Dial 111</h1>
                    <p>
                      Pathlab endeavours to provide the best quality care at all
                      times, however if you have a problem or concern about the
                      service you receive, please tell us.
                    </p>
                    <p>
                      General enquiries or feedback? Please fill out our contact
                      form.
                    </p>
                    <hr />
                    <h4>When we receive a complaint we will:</h4>
                    <ul>
                      <li>
                        Acknowledge receipt of your complaint in the first
                        instance.
                      </li>
                      <li>
                        Carefully review and investigate all the issues and
                        concerns highlighted in the complaint.
                      </li>
                      <li>
                        Rectify any incorrect procedures or processes if
                        necessary and take any required corrective action to
                        ensure the problem does not reoccur.
                      </li>
                      <li>
                        Inform you of the investigation and action we have
                        taken.
                      </li>
                    </ul>
                    <hr />
                    <h4>Setting up an account?</h4>
                    <p>
                      Pathlab offers a comprehensive billing option for our
                      commercial clients. This can easily be arranged with our
                      accounts team who can assist with your invoicing
                      requirements - contact them on (07) 578 7073, ext 6603.
                    </p>
                  </div>
                  <hr className="is-tablet" />
                  <div className="column is-6-desktop">
                    <h1>Contact Us</h1>
                    <p>
                      Do not submit medical, personal or accounts information
                      via this form.
                    </p>
                    <p>
                      For sensitive enquiries call{" "}
                      <strong>
                        <a className="has-text-primary" href="tel:+6475787073">
                          +64-7-578-7073
                        </a>
                      </strong>
                      <br />
                      <strong>Please note: </strong>Pathlab is unable to provide
                      results directly to patients unless at the authority of
                      your GP.
                    </p>
                    <p>
                      For medical assistance please contact your GP or in case
                      of emergency dial 111.
                    </p>
                    <p>
                      Please note that these messages are only monitored during
                      business hours and it may take a business day or more for
                      a response, depending on your enquiry.
                    </p>
                    <hr />
                    <Link to={`/contact-us`} className="button is-primary">
                      Fill in our Secure Contact Form
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </Layout>
    );
  }
}
